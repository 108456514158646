<template>
  <div class="new-detail">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>邀请好友</span>
      <span></span>
    </header>
    <div class="article">
      <div class="title">邀请好友使用赠送优惠券</div>
      <div class="author">
        <span class="col-xs-6">{{ author }}</span>
        <span class="col-xs-6 pull-right">{{ time }}</span>
      </div>
      <div class="img"><img :src="img" /></div>
      <div class="desc">{{ desc }}</div>
      <hr />
      <div class="content"></div>
    </div>
  </div>
</template>
<script>
import { getNotifyInfo } from '@/api/website.js';
import { formatDate } from '@/utils/index.js';
import { BASE_URL } from '@/api/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      title: '',
      author: '',
      time: '',
      desc: '',
      img: '',
      content: '',
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {},
  methods: {
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement('div');
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      //console.log(output);
      return output;
    },
    loadData() {
      var notifyId = this.$route.query.id;
      getNotifyInfo(notifyId).then(res => {
        if (res.Result == true) {
          let article = res.DataDict['data'];
          this.title = article.sTitle;
          this.author = article.sEmployee;
          this.content = this.htmlDecode(article.sContent);

          this.img = article.sImage;
          if (!this.img.startsWith('http')) {
            this.img = BASE_URL + this.img;
          }
          this.time = getValidTime(article.dCreateTime);
          document.querySelector('.content').innerHTML = this.content;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/_var';
.article {
  margin: rem(20px);
  margin-top: rem(100px);
  background: #fff;
  max-width: 100%;
  padding: rem(20px);
  .title {
    font-size: rem(40px);
    text-align: left;
    padding: rem(15px);
  }
  .img img {
    width: 100%;
    height: rem(260px);
    border: 1px solid #efefef;
    border-radius: 10px;
  }
  .content img {
    max-width: 100%;
  }
  .author {
    font-size: rem(18px);
    padding: rem(15px);
  }
}
</style>
